function OrderStatus(Status){
    switch(Status){
        case 1: return 'สร้าง';
        case 2 :return 'ส่งใบเสนอราคา - Inquiry for QT';
        case 3 :return 'ติดตาม';
        case 4 :return 'รอ - Hold';
        case 5 :return 'ชำระมัดจำ - Deposit';
        case 6: return 'ยกเลิก - NA';
        case 7: return "รอชำระมัดจำ - W-Deposit";
        case 10 : return "จบงาน";
        default: return '-';
    }
    /**
     1=> Create
2 => Inquiry for QT
3=> Process
4 => Hold
5=> Deposit
6=> NA
     */
} 

function OrderStatusLabel(Status){
    switch(Status){
        case 1: return '<span class="badge bg-secondary">สร้าง</span>';
        case 2 :return '<span class="badge bg-secondary">ส่งใบเสนอราคา - Inquiry for QT</span>';
        case 3 :return '<span class="badge bg-secondary">ติดตาม</span>';
        case 4 :return '<span class="badge bg-info">รอ - Hold</span>';
        case 5 :return '<span class="badge bg-success">ชำระมัดจำ - Deposit</span>';
        case 6 :return '<span class="badge bg-danger">ยกเลิก - NA</span>';
        case 7 :return '<span class="badge bg-warning">รอชำระมัดจำ - W-Deposit</span>';
        case 10 :return '<span class="badge bg-success">จบงาน</span>';
        default: return '-';
    }
    /**
     1=> Create
2 => Inquiry for QT
3=> Process
4 => Hold
5=> Deposit
6=> NA
     */
} 

function AppointmentType(Type){
    switch(Type){
        case 0: return 'ไม่นัดหมาย';
        case 1: return 'นัดหมาย';
        case 2: return 'นัดชำระเงิน';
        case 3: return 'นัดหมายติดตาม';
    }
}
const OrderStatusList = [1,2,3,4,5,6,7,10];

export  {OrderStatus,OrderStatusList,OrderStatusLabel,AppointmentType}